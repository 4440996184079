@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800&display=swap");

@layer base {
  html {
    font-family: "Poppins", sans-serif;
    /* overflow-x: hidden; */
  }

  ::placeholder {
    font-size: 16px;
  }
}

@layer components {
  .section-title {
    @apply mt-[50px] mb-[50px] text-[25px] lg:text-[30px] text-center;
  }
  .featuresH1Sm {
    grid-area: 2/1/2/1;
  }
  .featuresH2Sm {
    grid-area: 4/1/4/1;
  }
  .featuresH3Sm {
    grid-area: 6/1/6/1;
  }
  .featuresH4Sm {
    grid-area: 8/1/8/1;
  }
  .featuresH1Md {
    grid-area: 2/1/2/2;
  }
  .featuresH2Md {
    grid-area: 2/2/2/3;
  }
  .featuresH3Md {
    grid-area: 4/1/4/2;
  }
  .featuresH4Md {
    grid-area: 4/2/4/3;
  }
  .featuresH1_2xl {
    grid-area: 2/1/3/2;
  }
  .featuresH2_2xl {
    grid-area: 2/2/3/3;
  }
  .featuresH3_2xl {
    grid-area: 2/3/3/4;
  }
  .featuresH4_2xl {
    grid-area: 2/4/3/5;
  }
  .customMargin {
    margin: 0 auto;
  }

  .customBoxShadow {
    box-shadow: 0 0 15px 2px rgba(106, 109, 112, 0.288);
  }

  .customBoxShadowOnHover {
    box-shadow: -10px -10px 15px #b6abab73,
      10px 10px 13px rgba(106, 109, 112, 0.288);
  }

  .numbersProcent {
    grid-area: 2/1/3/2;
  }
  .numbersText {
    grid-area: 3/1/4/2;
  }
  .numbersNumber {
    grid-area: 2/2/3/3;
  }
  .numbersText2 {
    grid-area: 3/2/4/3;
  }
  .numbersYears {
    grid-area: 2/3/3/4;
  }
  .numbersText3 {
    grid-area: 3/3/3/4;
  }
  .copyBar {
    grid-area: 2/1/3/4;
  }

  .mapboxgl-control-container {
    display: none !important;
  }

  .spinner:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 4px;
    border-radius: 90%;
    border: 4px solid teal;
    border-color: #f58445 transparent #f58445 #f58445;
    animation: spinner 1.2s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
  }
  .custom-file-input::before {
    width: 150px;
    height: 40px;
    padding: 5px 10px;
    content: "Wybierz zdjęcie";
    display: inline-block;
    font-size: 16px;
    background: #ffffff;
    border: 2px solid #f58445;
    border-radius: 0.25rem;
    outline: none;
    -webkit-user-select: none;
    cursor: pointer;
  }

  .leaflet-container {
    width: 100vw;
    height: 100vh;
  }
}
